import { dialog } from "nq-component";
import BaseFormPresenter from "../../../base/BaseFormPresenter";
import classNames from "../../../classNames";
import { findObjectUseCase } from "../../../usecases/object";

class NewTransactionPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
    this.expense = object;
  }

  async submit() {
    const collection = this.view.getCollectionName();
    const expense = this.view.getCollectionExpense();

    const getgmd = await findObjectUseCase().execute("gmd_accounts");
    const combineBal = getgmd.reduce(
      (total, account) => total + account.balance,
      0
    );

    const accountBalance = this.object.account?.balance;
    const old_amount = this.object.amounts.replace(/,/g, "");
    const newAmount = Number(old_amount);
    const deduct = accountBalance - newAmount;
    const add = accountBalance + newAmount;

    if (this.change.types.type === "Money Out") {
      this.change.account_balance = combineBal - newAmount;
    } else if (this.change.account?.name === "GMD Revolving Fund") {
      this.change.account_balance = Number(add);
      if (this.change.fund_source) {
        this.change.fund_source.balance =
          this.change.fund_source.balance - old_amount;
      }
    } else {
      this.change.account_balance = combineBal + newAmount;
    }
    const clientName = this.change.clients?.name ?? "";
    this.change.clients = clientName;

    this.change.account.balance =
      this.change.types.type === "Money Out" ? deduct : add;
    const newBalance = this.change.account;
    const newFunds = this.change?.fund_source || newBalance;
    const clients = this.change.clients;

    const client_name = this.object.clients
      ? { id: this.object.clients?.id }
      : "";
    console.log("adobo", client_name);
    const id = this.object.types?.id;
    const amount = this.change.account.balance;
    const project_name = this.view.state.selectedProject
      ? this.view.state.selectedProject
      : "";
    this.change.types = { id: this.change.types?.id };
    const project_name_expense = { id: project_name?.id };

    if (project_name === null || project_name === undefined) {
      this.change.project_name = "";
    } else {
      this.change.project_name = { id: project_name?.id };
    }

    if (newBalance.balance < 0) {
      alert("Alert: Insufficient funds in the account.");
      return;
    }

    if (newFunds.balance < 0) {
      alert("Alert: Insufficient funds in the revolving fund.");
      return;
    }
    console.warn("this.change", this.change);
    console.warn("this.object", this.object);
    console.warn("state", this.view.state);

    try {
      console.log("this.change", this.change);
      console.log("this.object", this.object);
      await this.upsertUseCase.execute(collection, this.change);
      {
        this.change.types.type === "Money Out" &&
          this.upsertUseCase.execute(expense, {
            client_name,
            project_name_expense,
            id,
            amount,
          });
      }

      const gmdobj = {
        id: this.change.account.id,
        balance: this.change.account?.balance,
        name: this.change.account.name,
      };

      console.log("GMDOBJ: ", gmdobj);
      console.log("PROJCLI", this.change.project_name, this.change.client_name);

      await this.upsertUseCase.execute("gmd_accounts", gmdobj);
      this.view.showSuccessSnackbar("Transaction Added Successfully");
      // await this.view.navigateTo("/accounts");
    } catch (error) {
      throw error;
    }
  }
}

export default NewTransactionPresenter;
