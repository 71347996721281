import React from "react";

const StatusButtons = ({
  index,
  object,
  onClickApprove,
  handleEdit,
  toggleModal,
}) => {
  const { statuses } = object;

  const renderButtons = () => {
    switch (statuses) {
      case "Pending":
        return (
          <>
            <button
              className="btn text-white me-1"
              onClick={() => onClickApprove(index)}
              style={{ backgroundColor: "#4aab73" }}
            >
              <i className="bi bi-check me-1"></i>Approve
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleEdit(index, object)}
            >
              <i className="bi bi-pencil-square me-1"></i>Edit
            </button>
          </>
        );

      case "Approved":
      case "Partially Paid":
        return (
          <div className="d-flex">
            <button
              className="btn text-white me-1"
              onClick={() => toggleModal(object, false)}
              style={{ backgroundColor: "#ebbf38" }}
            >
              <i className="bi bi-plus me-1"></i>Receive
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleEdit(index, object)}
            >
              <i className="bi bi-file-earmark-fill"></i> View
            </button>
          </div>
        );

      case "Paid":
        return (
          <button
            className="btn text-white"
            onClick={() => handleEdit(index, object)}
            style={{ backgroundColor: "#ebbf38" }}
          >
            <i className="bi bi-file-earmark-fill"></i> View
          </button>
        );

      default:
        return null;
    }
  };

  return <div className="me-1">{renderButtons()}</div>;
};

export default StatusButtons;
