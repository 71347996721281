import BaseListPresenter from "../../base/BaseListPresenter";
import { dialog } from "nq-component";
import UpsertUseCase from "../../usecases/object/UpsertUseCase";
import { findObjectUseCase, deleteObjectUseCase } from "../../usecases/object";
import printComponent from "../../printComponent";

class InvoicesPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    deleteObjectUseCase,
    getObjectUseCase
  ) {
    super(view, findObjectUseCase, countObjectUseCase, deleteObjectUseCase);
    this.view = view;
    this.upsertUseCase = new UpsertUseCase();
    this.deleteObjectUseCase = deleteObjectUseCase;

    this.state = {
      selectedAccount: "",
    };
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.include = [
      "services",
      "services.questions",
      "services.questions.service",
      "client_name",
      "project_name",
    ];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  componentDidUpdate(prevProps) {
    const prevClassName = prevProps.params.name;
    const newClassName = this.view.getCollectionName();
    //if collection change
    if (prevClassName !== newClassName) {
      this.init();
      this.getObjects();
    }
  }

  async onClickItemDelete(index) {
    const object = this.objects[index].id;
    const collection = this.view.getCollectionName();
    try {
      await this.deleteObjectUseCase.execute(collection, object);
      this.objects.splice(index, 1);

      if (this.objects) {
        this.view.setObjects(this.objects);
      }
    } catch (error) {}
  }

  handleAccountChange = (event) => {
    this.setState({ selectedAccount: event.target.value });
  };
  onChange(object) {
    console.warn(object);
    this.change = object;
    this.object = object;
  }

  onClickItem() {
    this.view.navigateTo("/create-invoices");
  }

  async handleApprove(index, objects) {
    const object = this.objects[index];
    object.statuses = "Approved";
    await this.upsertUseCase.execute("invoices", object);
    this.view.navigateTo("/invoices");
    this.view.reload();
  }
  formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
    }).format(amount);
  };

  // NEWWWWWWWWWWWWWWWWWWW
  onChangeFilter(type, value, field) {
    const where = { ...this.view.state.filters };

    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };

          if (field === "client_name") {
            const newSelectedClient = value.id;
            const hasChanged =
              this.view.state.selectedClient !== newSelectedClient;

            this.view.setState({ selectedClient: newSelectedClient }, () => {
              this.fetchProjectsByClient(newSelectedClient);
              if (!hasChanged) {
                this.fetchProjectsByClient(newSelectedClient);
              }
            });
          }
        } else {
          if (field === "client_name") {
            this.view.setState(
              {
                selectedClient: null,
                filteredProjects: [],
                filters: {},
              },
              () => {
                delete where["project_name"];
                this.filterSubmit({});
              }
            );
          }
          delete where[field];
        }
        break;

      case "Boolean":
        where[field] = value;
        break;

      default:
        if (value) {
          where[field] = { $regex: value, $options: "i" };
        } else {
          delete where[field];
        }
    }

    this.view.setState({ filters: where }, () => {
      this.filterSubmit(where);
    });
  }

  async fetchProjectsByClient(clientId) {
    findObjectUseCase()
      .execute("projects")
      .then((projects) => {
        const filteredProjects = projects
          .filter((obj) => obj.client?.id === clientId)
          .map((obj) => ({ id: obj.id, name: obj.name }))
          .filter(
            (value, index, self) =>
              self.findIndex((v) => v.id === value.id) === index
          );

        this.view.setState({ filteredProjects });
        console.log("filtered projects:", this.state.filteredProjects);
      })
      .catch((err) => console.error("Error fetching projects:", err));
  }

  onClickExport(index) {
    this.view.exportPDF(index);
  }

  async exportPDF() {
    try {
      // Fetch services and invoices_final concurrently
      const [services, fetchedData] = await Promise.all([
        this.findObjectUseCase.execute("services"),
        this.findObjectUseCase.execute("invoices_final", {
          include: [
            "All",
            "client_name",
            "services",
            "services.questions",
            "services.questions.service",
            "project_name",
          ],
        }),
      ]);

      const selectedItem = this.view.state.selected[0];
      const fetchedObject = fetchedData.find(
        (item) => item.id === selectedItem?.id
      );

      this.view.setState({
        fetchobject: fetchedObject,
        services: services,
      });

      if (selectedItem) {
        printComponent(
          this.view.contractPDF.current,
          `Invoices_${selectedItem.invoice_num}_${selectedItem.client_name?.name}`
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async onClickDeleteSelected() {
    const selected = this.view.getSelected();
    const collection = this.view.getCollectionName();

    try {
      await this.view.showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      });
      for (const obj of selected) {
        console.log(obj.id);
        await this.deleteObjectUseCase.execute(collection, obj.id);
        const index = this.objects.indexOf(obj);
        this.objects.splice(index, 1);
        this.view.setObjects(this.objects);
      }
      this.view.setSelected([]);
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }

  async onClickApprove(index, status) {
    const collection = "invoices_final";
    const object = this.objects[index];

    object.statuses = status === "Approved" ? "Approved" : status;
    object.client_name = { id: object.client_name.id };
    object.project_name = { id: object.project_name.id };

    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.statuses === object.statuses) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  handleEdit(index, objects) {
    const object = this.objects[index];
    this.view.navigateTo("/edit-invoices/" + object.id);
  }

  onChange2(value, field) {
    if (!this.view.state.change || typeof this.view.state.change !== "object") {
      this.view.setState({ change: {} });
    }
    const updatedChange = { [field]: value };

    this.view.setState((prevState) => ({
      change: {
        ...prevState.change,
        ...updatedChange,
      },
    }));
  }

  //handleSubmit
  async handleSubmit(
    index,
    object,
    change,
    accounts,
    obj,
    withhold,
    newAmount,
    withheldAmount
  ) {
    if (!this.validateInput(change)) return;

    try {
      const { invoice, mode, cashInBank } = await this.fetchRequiredData(obj);

      const paymentMethodObject = this.getPaymentMethod(
        change.payment_method,
        mode
      );

      const selectedAccount = this.getSelectedAccount(change.account, accounts);

      const amountCheck = this.getAmountCheck(change.amount);
      const collectibles = invoice[index]?.collectibles;

      if (
        !this.validateAmount(amountCheck, change, collectibles, withheldAmount)
      )
        return;

      const invObj = this.processInvoice(
        invoice,
        index,
        amountCheck,
        change,
        withheldAmount,
        collectibles
      );
      const transObj = this.createTransactionObject(
        invObj,
        amountCheck,
        selectedAccount,
        cashInBank,
        withhold,
        change
      );
      change.account = { id: selectedAccount?.id };
      change.payment_method = { id: paymentMethodObject?.id };
      change.invoice_num = invoice[index]?.invoice_num;

      // Update records in parallel
      await this.updateDatabase(change, selectedAccount, invObj, transObj);

      // UI updates
      this.view.submitting();
      this.view.submissionSuccess();
      await this.view.showSuccess("", "Payment Successful");
      await this.view.reload();
    } catch (error) {
      console.error("Error processing invoice:", error);
      this.showDialog(
        "An error occurred while processing the invoice. Please try again."
      );
    }
  }

  async fetchRequiredData(obj) {
    try {
      const [invoice, mode, cashInBank] = await Promise.all([
        findObjectUseCase().execute("invoices_final", {
          where: { id: obj },
          // include: ["all"],
        }),
        this.findObjectUseCase.execute("paymode"),
        this.findObjectUseCase.execute("gmd_accounts"),
      ]);

      return { invoice, mode, cashInBank };
    } catch (error) {
      console.error("Error fetching data:", error);
      throw new Error("Failed to fetch required data");
    }
  }

  validateInput(change) {
    if (!change) {
      this.showDialog(
        "Submission cannot proceed without completing required fields."
      );
      return false;
    }

    const missingFields = ["payment_method", "account", "amount"].filter(
      (key) => !Object.keys(change).includes(key)
    );

    if (missingFields.length > 0) {
      this.showDialog("Missing required fields: " + missingFields.join(", "));
      return false;
    }

    return true;
  }

  validateAmount(amountCheck, change, collectibles, withheldAmount) {
    const withHoldAmount = collectibles - withheldAmount;
    if (amountCheck > (change.withholdTax ? withHoldAmount : collectibles)) {
      this.showDialog("Amount must be valid");
      return false;
    }
    return true;
  }

  getPaymentMethod(paymentMethodId, mode) {
    return mode.find((method) => method.id === paymentMethodId) || null;
  }

  getSelectedAccount(accountId, accounts) {
    return accounts.find((item) => item.id === accountId) || null;
  }

  getAmountCheck(amount) {
    return parseFloat(amount.replace(/,/g, ""));
  }

  processInvoice(
    invoice,
    index,
    amountCheck,
    change,
    withheldAmount,
    collectibles
  ) {
    const invObj = { ...invoice[index] };

    invObj.collectibles = change.withholdTax
      ? Math.abs(collectibles - withheldAmount - amountCheck)
      : Math.abs(amountCheck - collectibles);

    invObj.withheldAmount =
      isNaN(invObj?.withheldAmount) || invObj?.withheldAmount === undefined
        ? 0
        : invObj?.withheldAmount;

    if (!isNaN(withheldAmount) && withheldAmount !== undefined) {
      invObj.withheldAmount += withheldAmount;
    }

    invObj.received = (invoice[index]?.received || 0) + amountCheck;
    invObj.statuses = invObj.collectibles === 0 ? "Paid" : "Partially Paid";
    invObj.isWithHold = invObj?.withheldAmount ? true : false;

    return invObj;
  }

  createTransactionObject(
    invObj,
    amountCheck,
    selectedAccount,
    cashInBank,
    withhold,
    change
  ) {
    const mio = {
      id: "5c3d711b-69ae-413a-9c2f-ea49a11f64b4",
      type: "Money In",
    };
    return {
      clients: invObj.client_name?.name,
      amounts: String(amountCheck),
      account: { id: change.account },
      note: "Invoice #" + invObj.invoice_num,
      types: { id: mio.id },
      account_balance:
        cashInBank.reduce((total, acc) => total + acc.balance, 0) + amountCheck,
      project_name: {
        id: invObj.project_name.id,
        // name: invObj.project_name.name,
      },
      withholdTax: withhold || false,
      withheldPercent: change?.withheldPercent || 0,
      withheldAmount: change.withholdTax
        ? String((invObj.tax * change.withheldPercent) / 100)
        : 0,
    };
  }

  async updateDatabase(change, selectedAccount, invObj, transObj) {
    change.amount = parseFloat(change.amount.replace(/,/g, ""));

    const accObj = {
      name: selectedAccount?.name,
      balance: selectedAccount.balance + change.amount,
      id: change.account.id,
    };

    // Upsert records in parallel
    await Promise.all([
      this.upsertUseCase.execute("payvoice", change),
      this.upsertUseCase.execute("invoices_final", invObj),
      this.upsertUseCase.execute("transaction", transObj),
      this.upsertUseCase.execute("gmd_accounts", accObj),
    ]);
  }

  showDialog(message) {
    dialog.fire({
      html: (
        <>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <span
              className="bi bi-exclamation-triangle-fill"
              style={{ fontSize: "2em", color: "red" }}
            ></span>
          </div>
          <div
            style={{
              fontSize: "1.2em",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {message}
          </div>
          <button
            className="btn mb-2 mt-2 me-2"
            style={{ backgroundColor: "#EBBD2F", float: "right" }}
            onClick={() => dialog.close()}
          >
            Confirm
          </button>
        </>
      ),
      footer: false,
    });
  }
}

export default InvoicesPresenter;
