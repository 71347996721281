import React from "react";

const SummaryCard = ({
  totalOverCollectibles,
  totalDueCollectibles,
  totalReceived,
  formatCurrency,
}) => {
  return (
    <div className="card p-4" style={{ opacity: ".7", width: "400px" }}>
      <ul className="list-unstyled ms-1 text-truncate">
        <li>
          <span className="ms-2 fw-light fw-bold">Overdue: </span>
          <span className="text-nowrap text-danger">
            {formatCurrency(totalOverCollectibles)}
          </span>
        </li>
        <li>
          <span className="ms-2 fw-light fw-bold">Not Overdue Yet: </span>
          <span className="text-nowrap text-orange">
            {formatCurrency(totalDueCollectibles)}
          </span>
        </li>
        <li>
          <span className="ms-2 fw-light fw-bold">Payment Received: </span>
          <span className="text-nowrap text-success">
            {formatCurrency(totalReceived)}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default SummaryCard;
