import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";
class DesignFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    console.log("this.change", this.change);

    try {
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    const currentUser = this.view.getCurrentUser();
    const queryPID = {
      where: {
        id: this.view.props.params.pid,
      },
    };
    const queryCID = {
      where: {
        id: this.view.props.params.cid,
      },
    };

    const pid = await findObjectUseCase().execute("projects", queryPID);
    const cid = await findObjectUseCase().execute("clients", queryCID);
    console.log("projectnameee", this.object.project_name);

    if (!this.change.employee) {
      // this.change.employee = currentUser;
      this.change.employee = {
        id: currentUser.id ?? currentUser?.id,
      };
    }

    if (this.view.props.params.pid && this.view.props.params.cid) {
      this.change.project_name = pid[0];
      this.change.client_name = cid[0];
    } else if (!this.change.project_name || !this.object.project_name) {
      // this.change.project_name = this.view.state.selectedProject
      //   ? this.view.state.selectedProject
      //   : this.object.project_name;
      this.change.project_name = {
        id: this.view.state.selectedProject?.id ?? this.object.project_name.id,
      };
    }
    if (!this.object.project_name) {
      // this.object.project_name = this.view.state.selectedProject;
      this.object.project_name = {
        id: this.view.state.selectedProject?.id ?? this.object.project_name.id,
      };
    }
    if (!this.object.client_name) {
      // this.object.client_name = this.change.client_name;
      this.object.client_name = {
        id: this.change.client_name?.id ?? this.object.client_name?.id,
      };
    }
    if (this.change.client_name) {
      // this.object.client_name = this.change.client_name;
      this.change.client_name = {
        id: this.change.client_name?.id ?? this.object.client_name?.id,
      };
    }

    if (!this.change.statuses) {
      this.change.statuses = "For Approval";
    }
    if (this.change.statuses === "Disapproved") {
      this.change.statuses = "For Approval";
    }

    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }

    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>

              {currentUser.roles.some(
                (role) =>
                  role.id === "admin" ||
                  role.id === "hrAdmin" ||
                  role.id === "masterAdmin"
              ) ? (
                <>
                  <h4 className="fw-bold">Success!</h4>
                  <p className="m-0">Data has been successfully saved.</p>
                </>
              ) : (
                <>
                  <h4 className="fw-bold">Request Successfully Submitted.</h4>
                  <p className="m-0">Please wait for the admin approval.</p>
                </>
              )}
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      if (this.view.props.params.pid && this.view.props.params.cid) {
        await this.view.navigateTo(
          "/view/project/" +
            this.view.props.params.pid +
            "/" +
            this.view.props.params.cid +
            "/" +
            this.view.props.params.pName
        );
      } else {
        await this.view.navigateTo("/design");
      }
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default DesignFormPresenter;
