import React from "react";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import { restUseCase } from "../../usecases/rest";
import InputFactory from "../../components/InputFactory";
import InputFactory2 from "../../components/InputFactory2";
import PurchaseOrderFormPresenter from "./PurchaseOrderFormPresenter";
import printComponent from "../../printComponent";
import PurchaseOrderPrint from "./components/PurchaseOrderPrint;";
import Drop from "../../components/DropdownFilter";
import { emailUseCase } from "../../usecases/emails";
// import ItemInput from "./components/ItemInput";
import ItemInput from "../../components/ItemInput";

class PurchaseOrderFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      items: [{ name: "", amounts: "", quantity: 1, chart_of_accounts: null }],
      advanced: false,
      totalItems: 0,
      discountType: "",
      totalAmount: 0,
      selectedProject: null,
      filtered: [],
      chartOptions: [],
    };
    this.presenter = new PurchaseOrderFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
    this.contractPDF = React.createRef();

    this.initializeChartOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.object.items !== this.state.object.items) {
      if (this.state.object.items) {
        this.setState({ items: this.state.object.items });
      }
    }
    console.log("itemsss", this.state.items, this.state.object.items);
  }

  async initializeChartOptions() {
    try {
      const chartOptions = await findObjectUseCase().execute(
        "chart_of_accounts"
      );
      this.setState({ chartOptions });
    } catch (error) {
      console.error("Error fetching chart options:", error);
    }
  }

  getCollectionName() {
    return "purchase_orders";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }
  onChangeFilter(value) {
    this.presenter.onChangeFilter(value);
  }
  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  onApproved(e) {
    e.preventDefault();
    this.presenter.approved();
  }
  onDisapproved(e) {
    e.preventDefault();
    this.presenter.disapproved();
  }

  onSend(e) {
    e.preventDefault();
    this.presenter.send();
  }

  onPaid(e) {
    e.preventDefault();
    this.presenter.paid();
  }

  getAcl() {
    // const roles = this.getCurrentRoles();
    // const aclRoles = roles.map((r) => `role:${r.name}`);
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin"],
      write: [user.id, "role:admin"],
    };
  }

  exportPDF() {
    //console.log(this.contractPDF)
    printComponent(this.contractPDF.current, "Contracts");
  }

  onClickExport() {
    this.presenter.onClickExport();
  }

  handleDiscountType(type) {
    this.setState({ discountType: type });
  }

  async onChange(value, field) {
    const updatedObject = { ...this.state.object, [field]: value };
    if (field === "client_name") {
      const clientName = value.id;
      const proj = await findObjectUseCase().execute("projects");
      console.warn("proj", proj);
      const filteredProjects = proj
        .filter((project) => project.client?.id === clientName)
        .map((project) => ({
          name: project.name,
          id: project.id,
        }));
      this.setState({
        filtered: filteredProjects,
        selectedProject:
          filteredProjects.length > 0 ? filteredProjects[0] : null,
      });
    } else if (field === "project_name" && !value) {
      this.setState({ filtered: [], selectedProject: null });
    }
    // else if (Array.isArray(value)) {
    //   this.setState({ itemsAmount: value });
    // }
    this.setState({ object: updatedObject });
    this.presenter.onChange(value, field);
  }
  handleProjectChange = (value) => {
    const selectedProject = this.state.filtered.find(
      (project) => project.id === value
    );
    this.setState({ selectedProject: selectedProject || null });
  };

  handleItemChange = (index, field, value) => {
    // const items = [...(this.state.object.items || this.state.items)];
    const items = [...this.state.items];
    items[index][field] = value;
    this.setState((prevState) => ({
      // object: { ...prevState.object, items },
      items,
    }));
  };

  handleAddItem = () => {
    // const items = this.state.object.items || this.state.items;
    const items = this.state.items;
    if (items.length >= 10) return;

    this.setState((prevState) => ({
      // object: {
      //   ...prevState.object,
      //   items: [
      //     ...items,
      //     // { name: "", amount: 0, quantity: 1, chart_of_accounts: null },
      //     { name: "", amounts: "", quantity: 1, chart_of_accounts: null },
      //   ],
      // },
      // items,
      items: [
        ...items,
        { name: "", amounts: "", quantity: 1, chart_of_accounts: null },
      ],
    }));
  };

  handleRemoveItem = (index) => {
    // const items = [...(this.state.object.items || this.state.items)];
    const items = [...this.state.items];
    items.splice(index, 1);
    this.setState((prevState) => ({
      // object: { ...prevState.object, items },
      items,
    }));
  };

  calculateTotal = () => {
    // const items = this.state.object.items || this.state.items;
    const items = this.state.items;
    const { discount } = this.state.object;
    // const itemsToCalculate = this.state.object.items || this.state.items;

    // const total = itemsToCalculate.reduce((sum, item) => {
    const total = items.reduce((sum, item) => {
      // const amount = parseFloat(item.amount || 0);
      const amount = parseFloat(item.amounts || 0);

      const quantity = parseInt(item.quantity || 0);
      return sum + amount * quantity;
    }, 0);

    // return discount
    //   ? this.state.discountType === "percent"
    //     ? total - (total * discount) / 100
    //     : total - discount
    //   : total;

    console.log("this.state.discountType", this.state?.discountType);
    // return discount
    //   ?  this.state.discountType === "percent" ||
    //     this.state.object?.discountType === "percent"
    //     ? total - total * (discount / 100) // Ensure correct percentage calculation
    //     : total - discount
    //   : total;
    return discount
      ? (this.state.discountType || this.state.object?.discountType) ===
        "percent"
        ? total - total * (discount / 100) // Ensure correct percentage calculation
        : total - discount
      : total;
  };

  render() {
    const { object, selectedProject, chartOptions, items } = this.state;
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();
    console.log("user", user);
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    const currentDate = this.getCurrentDateFormatted();
    console.warn("object", object.item);
    console.warn("object check", object);

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    const masterIdentifier = role[0].id === "masterAdmin";
    return (
      <>
        <div className="d-none">
          <div ref={this.contractPDF}>
            <PurchaseOrderPrint object={object} />
          </div>
        </div>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />

        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              {role.some((role) => role.id === "admin") ? (
                <h1
                  className="fw-bold mt-3 text-capitalize"
                  onClick={this.onClickBack.bind(this)}
                >
                  <i className="bi bi-chevron-left"></i>{" "}
                  {label + (schema.label || this.getCollectionName())}
                </h1>
              ) : (
                <h1 className="fw-bold mt-3 text-capitalize">
                  {label + (schema.label || this.getCollectionName())}
                </h1>
              )}

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3 py-4">
                    {this.getObjectId() !== undefined && (
                      <div className="mb-3">
                        <label className="form-label">PO#</label>
                        <InputFactory
                          type="Number"
                          field="po_num"
                          disabled={
                            role.some((role) => role.id === "admin") &&
                            (object.statuses === "Approved" ||
                              object.statuses === "Sent" ||
                              object.statuses === "Paid")
                              ? true
                              : role.some((role) =>
                                  role.id === "admin" ? false : true
                                )
                          }
                          object={object}
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}

                    <div className="mb-3">
                      <label className="form-label">Due Date</label>
                      <InputFactory
                        type="Date"
                        field="due_date"
                        object={object}
                        placeholder="Add Due Date"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        min={currentDate}
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Account</label>
                      <InputFactory
                        type="UserInputPointer"
                        dynamic={false}
                        target="gmd_accounts"
                        field="account"
                        object={object}
                        placeholder="Select Account Name"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        // excludeFields={[
                        //   "GMD Fund",
                        //   masterIdentifier ? "" : "PJG Abono",
                        // ]}
                        excludeFields={
                          user.roles[0].id === "admin" ||
                          user.roles[0].id === "user"
                            ? ["Management money"]
                            : ["GMD Fund"]
                        }
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Client Name</label>
                      <InputFactory
                        type="UserInputPointer"
                        dynamic={false}
                        target="clients"
                        field="client_name"
                        object={object}
                        placeholder="Select Client Name"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                      />
                    </div>
                    <div className="mb-3">
                      {this.state.filtered.length > 0 ||
                      selectedProject ||
                      object.project_name?.name ? (
                        <>
                          <label className="form-label">Project Name</label>
                          <Drop
                            placeholder={
                              selectedProject
                                ? "Select a project"
                                : object.project_name?.name
                            }
                            value={
                              selectedProject
                                ? selectedProject
                                : object.project_name?.name
                            }
                            options={this.state.filtered}
                            onChange={this.handleProjectChange}
                          />
                        </>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Vendor Name</label>
                      <InputFactory
                        type="ModdedInputPointer"
                        target="vendor"
                        field="vendor_name"
                        object={object}
                        placeholder="Select Vendor Name"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Vendor Email</label>
                      <InputFactory
                        type="String"
                        field="vendor_email"
                        object={object}
                        placeholder="Add Vendor Email"
                        defaultValue={object.vendor_name?.email}
                        onChange={this.onChange.bind(this)}
                        required={true}
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Vendor Address</label>
                      <InputFactory
                        type="String"
                        field="vendor_address"
                        object={object}
                        placeholder="Add Vendor Address"
                        defaultValue={object.vendor_name?.current_address}
                        onChange={this.onChange.bind(this)}
                        required={true}
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Items</label>
                      <div>
                        {
                          // (object.items || items).map((item, index) => (

                          //   this.state.object && this.state.object.items
                          //   ? this.state.object.items
                          //   : items
                          // ).map((item, index) => (
                          items.map((item, index) => (
                            <ItemInput
                              key={index}
                              index={index}
                              item={item}
                              chartOptions={chartOptions}
                              onChange={this.handleItemChange}
                              onRemove={this.handleRemoveItem}
                            />
                          ))
                        }
                      </div>
                      {
                        // (object.items || items).length < 10 && (
                        // this.state.object && this.state.object.items
                        // ? this.state.object.items.length
                        // : items.length) < 10 && (
                        items.length < 10 && (
                          <button
                            type="button"
                            className="btn btn-primary mb-3"
                            onClick={this.handleAddItem}
                          >
                            Add More Items
                          </button>
                        )
                      }
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Discount</label>
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Discount Type"
                      >
                        <button
                          type="button"
                          className={`btn ${
                            // this.state.discountType === "percent"
                            (this.state.discountType ||
                              this.state.object.discountType) === "percent"
                              ? "btn-primary"
                              : "btn-secondary"
                          } mx-2`}
                          onClick={() => this.handleDiscountType("percent")}
                        >
                          %
                        </button>

                        <button
                          type="button"
                          className={`btn ${
                            // this.state.discountType === "value"
                            (this.state.discountType ||
                              this.state.object.discountType) === "value"
                              ? "btn-primary"
                              : "btn-secondary"
                          }`}
                          onClick={() => this.handleDiscountType("value")}
                        >
                          P
                        </button>
                      </div>
                      <InputFactory
                        type="Number"
                        field="discount"
                        object={object}
                        placeholder={
                          // this.state.discountType === "percent"
                          // this.state.object.discountType === "percent"
                          this.state.discountType === "percent"
                            ? "Enter Discount Percentage"
                            : "Enter Discount Value"
                        }
                        onChange={(value) => this.onChange(value, "discount")}
                        required={false}
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                      />
                    </div>

                    <div className="mb-3">
                      {/* <label className="form-label">Items</label>
                      <InputFactory2
                        type="Relation"
                        _type="Related"
                        field="items"
                        target="items"
                        object={object}
                        onChange={this.onChange.bind(this)}
                        required={true}
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                        excludeFields={[
                          "supplier",
                          "tin#",
                          "or#",
                          "Chart of Accounts",
                        ]}
                      /> */}
                      <h5>
                        Total:{" "}
                        {formatCurrency(this.calculateTotal().toFixed(2))}
                      </h5>
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Chart of Accounts</label>
                      <InputFactory
                        type="ModdedInputPointer"
                        target="chart_of_accounts"
                        field="chart_of_accounts"
                        object={object}
                        placeholder="Select Chart of Accounts"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        disabled={
                          object.statuses === "Approved" ||
                          object.statuses === "Sent" ||
                          object.statuses === "Paid"
                        }
                      />
                    </div> */}
                    {/* <div className="mb-3">
                      <label className="form-label">Total: </label>
                      <InputFactory
                        type="Number"
                        disabled
                        value={formatCurrency(totalItem)}
                      />
                      {formatCurrency(object.totalAmount)}
                    </div> */}
                  </div>

                  {role.some(
                    (role) => role.id === "admin" || role.id === "masterAdmin"
                  ) && object.statuses === "Pending" ? (
                    <div className="d-flex justify-content-between">
                      <button
                        type="submit"
                        className="btn fs-sm me-3"
                        style={{ backgroundColor: "#EBBD2F" }}
                      >
                        <i className="bi bi-file-earmark-check me-2"></i>SAVE
                      </button>
                      <div>
                        <button
                          type="button"
                          className="btn fs-sm me-3 text-white"
                          style={{ backgroundColor: "red" }}
                          onClick={this.onDisapproved.bind(this)}
                        >
                          <i className="bi bi-file-earmark-check me-2"></i>
                          Cancel Purchase
                        </button>
                        <button
                          type="button"
                          className="btn fs-sm me-3 text-white"
                          style={{ backgroundColor: "#3FC65C" }}
                          onClick={this.onApproved.bind(this)}
                        >
                          <i className="bi bi-file-earmark-check me-2"></i>
                          Approve
                        </button>
                      </div>
                    </div>
                  ) : (role.some((role) => role.id === "admin") &&
                      object.statuses === "Approved") ||
                    object.statuses === "Sent" ? (
                    <div className="d-flex justify-content-between ">
                      <button
                        type="button"
                        className="btn  fs-sm me-3"
                        style={{ backgroundColor: "#EBBD2F" }}
                        onClick={this.onSend.bind(this)}
                      >
                        <i className="bi bi-file-earmark-check me-2"></i>Send
                      </button>
                      <button
                        type="button"
                        className="btn  fs-sm"
                        style={{ backgroundColor: "#EBBD2F" }}
                        onClick={this.onClickExport.bind(this)}
                      >
                        Download PDF
                      </button>
                    </div>
                  ) : object.statuses === "Approved" ||
                    object.statuses === "Sent" ? (
                    <div className="d-flex justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-light fs-sm"
                        onClick={this.onClickBack.bind(this)}
                      >
                        Back
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between ">
                      <button
                        type="submit"
                        className="btn  fs-sm me-3"
                        style={{ backgroundColor: "#EBBD2F" }}
                      >
                        <i className="bi bi-file-earmark-check me-2"></i>SAVE
                      </button>
                      <button
                        type="button"
                        className="btn btn-light fs-sm"
                        onClick={this.onClickBack.bind(this)}
                      >
                        CANCEL
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PurchaseOrderFormPage);
