import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";
class PurchaseOrderFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    const user = this.view.getCurrentUser();
    const payables = {};
    const orders = await findObjectUseCase().execute("purchase_orders");

    const budgetRequest = this.change;

    const items = budgetRequest.items || [];

    const itemNames = items.map(
      (item, index) => item.name || `itemsName${index + 1}`
    );
    const itemAmount = items.map(
      // (item, index) => item.amount || `itemsAmount${index + 1}`
      (item, index) => item.amounts || `itemsAmount${index + 1}`
    );
    const itemAmounts = items.map(
      // (item, index) => item.amount || `itemsAmount${index + 1}`
      (item, index) => item.amounts || `itemsAmounts${index + 1}`
    );

    let highestPoNum = 0;
    orders.forEach((item) => {
      if (item.po_num && item.po_num > highestPoNum) {
        highestPoNum = item.po_num;
      }
    });

    console.log("mnmnmn", this.object.id);

    if (this.object.id) {
      this.change.id = this.object.id;

      console.log(
        "this.view.state.discountType;",
        this.view.state.discountType
      );
      this.change.discountType = this.view.state.discountType;
      console.log("<<", this.change.discountType);
      console.log("this", this.change);
    } else {
      this.change.acl = this.view.getAcl();
      payables.project_name = this.view.state.selectedProject;
      payables.items = this.view.state.items;
      payables.purchaseOrderNumber = highestPoNum + 1;
      payables.accounts = this.object.account;
      payables.due_date = this.object.due_date;
      payables.amount = this.view.calculateTotal();

      payables.client_name = this.object.client_name;
      payables.vendor_name = this.object.vendor_name;
      //payables.pending_balance = this.object.totalAmount
      console.log(this.object);
      // await this.upsertUseCase.execute("payables", payables);
      console.log("this.change.payables", payables);
    }
    if (this.object.created_by) {
      this.change.created_by = { id: this.object.created_by?.id };
    } else {
      this.change.created_by = { id: user.id };
    }
    this.change.statuses = "Pending";
    if (this.object.po_num == undefined && this.change.po_num == undefined) {
      this.change.po_num = highestPoNum + 1;
    }
    delete this.change.created_by.isMaster;
    this.change.vendor_name = this.object.vendor_name;
    this.change.vendor_name = {
      id: this.change.vendor_name.id ?? this.object.vendor_name.id,
    };
    this.change.vendor_address = this.object.vendor_name?.current_address;
    this.change.vendor_email = this.object.vendor_name?.email;
    // this.change.total = this.object.totalAmount;
    // const project_name = this.view.state.selectedProject;
    this.change.total = this.view.calculateTotal();
    // this.change.project_name = this.view.state.selectedProject
    //   ? this.view.state.selectedProject
    //   : this.object.project_name;
    this.change.project_name = {
      id: this.view.state.selectedProject?.id ?? this.object.project_name.id,
    };

    // this.change.project_name = project_name;
    this.change.requesteeString = user.fullName;
    // this.change.pending_balance = this.object.totalAmount;
    this.change.pending_balance = this.view.calculateTotal();
    this.change.vendorString = this.change.vendor_name.name;
    this.change.discountType =
      this.view.state.discountType || this.object.discountType;
    // this.change.items = this.view.state.items;
    items.forEach((item, index) => {
      const itemKey = `items${index + 1}`;

      this.change[itemKey] =
        // item.name && item.amount ? `-> ${item.name} : ${item.amount}` : "";
        item.name && item.amounts
          ? `-> ${item.name} : ${String(item.amounts)}`
          : "";
    });

    this.change.items = (this.change.items ?? this.view.state.items)?.map(
      (item) => ({
        name: item.name,
        amounts: item.amounts,
        "or#": item["or#"], // Enclosed in quotes
        supplier: item.supplier,
        "tin#": item["tin#"], // Enclosed in quotes
        quantity: item.quantity,
        chart_of_accounts: {
          id: item.chart_of_accounts.id,
        },
      })
    );
    this.change.client_name = {
      id: this.change.client_name?.id ?? this.object.client_name.id,
    };
    // this.change.items1 = `${"->"} ${itemsName1} : ${itemsAmount1}` || "";
    // this.change.items2 = itemsName2
    //   ? `${"->"} ${itemsName2} : ${itemsAmount2}`
    //   : "";
    // this.change.items3 = itemsName3
    //   ? `${"->"} ${itemsName3} : ${itemsAmount3}`
    //   : "";
    // this.change.items4 = itemsName4
    //   ? `${"->"} ${itemsName4} : ${itemsAmount4}`
    //   : "";
    // this.change.items5 = itemsName5
    //   ? `${"->"} ${itemsName5} : ${itemsAmount5}`
    //   : "";
    // this.change.items6 = itemsName6
    //   ? `${"->"} ${itemsName6} : ${itemsAmount6}`
    //   : "";
    // this.change.items7 = itemsName7
    //   ? `${"->"} ${itemsName7} : ${itemsAmount7}`
    //   : "";
    // this.change.items8 = itemsName8
    //   ? `${"->"} ${itemsName8} : ${itemsAmount8}`
    //   : "";
    // this.change.items9 = itemsName9
    //   ? `${"->"} ${itemsName9} : ${itemsAmount9}`
    //   : "";
    // this.change.items10 = itemsName10
    //   ? `${"->"} ${itemsName10} : ${itemsAmount10}`
    //   : "";
    // this.change.created_by = user;

    // console.log("this.change.po", this.change);
    console.log("this.change.po test", this.change);

    try {
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  async submit() {
    // if (Object.values(this.change).length === 0) {
    if (
      Object.values(this.change).length === 0 &&
      this.view.state.items == this.view.state.object.items
    ) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      // this.view.showSuccessSnackbar("Successfully saved!");
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Data has been successfully saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/purchase-orders");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
  async approved() {
    const collection = this.view.getCollectionName();
    const payables = {};
    const orders = await findObjectUseCase().execute("purchase_orders");

    let highestPoNum = 0;
    orders.forEach((item) => {
      if (item.po_num && item.po_num > highestPoNum) {
        highestPoNum = item.po_num;
      }
    });
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    this.change.statuses = "Approved";

    try {
      this.view.submitting();
      console.warn(payables);
      payables.project_name = this.object.project_name;
      payables.items = this.object.items;
      payables.purchaseOrderNumber = highestPoNum;
      payables.accounts = this.object.account;
      payables.due_date = this.object.due_date;
      // payables.amount = this.object.totalAmount;
      payables.amount = this.view.calculateTotal();
      payables.client_name = this.object.client_name;
      payables.vendor_name = this.object.vendor_name;
      payables.statuses = this.object.statuses;
      payables.id = this.object.id;
      await this.upsertUseCase.execute(collection, this.change);
      await this.upsertUseCase.execute("payables", payables);

      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully Approved!");
      await this.view.navigateTo("/purchase-orders");

      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  async disapproved() {
    const collection = this.view.getCollectionName();

    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    this.change.statuses = "Cancelled";

    try {
      this.view.submitting();
      await this.upsertUseCase.execute(collection, this.change);
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully Cancelled!");
      await this.view.navigateTo("/purchase-orders");

      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  async send() {
    const collection = this.view.getCollectionName();
    const method = "POST";
    const path = "/send-email/fd499c79-5d21-4697-a04b-853d1553c31d";
    const obj = {
      to: this.object.vendor_email,
      body: `Hi ${this.object.vendor_name?.name}, Please see attached PO #${this.object?.po_num}. for your reference. Regards, GMD Productions Inc`,
    };

    // const payables = {};
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    this.change.statuses = "Sent";

    try {
      this.view.submitting();
      await this.upsertUseCase.execute(collection, this.change);
      await this.restUseCase.execute(method, path, {
        body: obj,
      });

      this.view.submissionSuccess();

      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Email Successfully Sent!</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/purchase-orders");

      this.change = {};
    } catch (error) {
      throw error;
    }
  }

  // async paid() {
  //   const collection = this.view.getCollectionName();

  //   if (this.object.id) {
  //     this.change.id = this.object.id;
  //   } else {
  //     this.change.acl = this.view.getAcl();
  //   }

  //   this.change.statuses = "Paid";

  //   try {
  //     this.view.submitting();
  //     await this.upsertUseCase.execute(collection, this.change);
  //     this.view.submissionSuccess();
  //     this.view.showSuccessSnackbar("Paid Successfully!");
  //     await this.view.navigateTo("/purchase-orders");

  //     this.change = {};
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  onClickExport() {
    this.view.exportPDF();
  }
}

export default PurchaseOrderFormPresenter;
