import BaseFormPage from "../../../base/BaseFormPage";
import NavBar from "../../../components/navbar";
import { getObjectUseCase, upsertUseCase } from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";
import UserFormPresenter from "./UserFormPresenter";
import CreateUserForm from "../components/CreateUserForm";
import withRouter from "../../../withRouter";
import dialog from "../../../components/Modal/dialog";
import createPromise from "../../../createPromise";
import AddUser from "../components/Modals/AddUser";
import toast, { Toaster } from "react-hot-toast";

class UserFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {}, modalState: false };
    this.presenter = new UserFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase()
    );
  }

  getCollectionName() {
    return "users";
  }

  isEditing() {
    const params = this.getParams();
    if (params.hasOwnProperty("id")) {
      return true;
    } else {
      return false;
    }
  }

  showModal() {
    this.setState({ modalState: true });
  }

  showAddUserDialog() {
    const promise = createPromise();
    dialog.fire({
      html: <AddUser isEditing={this.isEditing()} />,
      positiveButton: this.isEditing() ? "Edit" : "Add",
      negativeButton: "Cancel",
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
    });
    return promise;
  }

  showToast(message) {
    toast(message, {
      position: "bottom-right",
      style: {
        background: "#4FBC4C",
        color: "white",
        fontWeight: "600",
      },
    });
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*"],
      write: [user.id, "masterAdmin", "hrAdmin"],
    };
  }

  excludeRates(object) {
    if (!object?.hasOwnProperty("roles")) return ["dailyRate", "monthlyRate"];
    const { roles } = object;
    if (
      roles.some((role) => role.id === "user") ||
      roles.some((role) => role.id === "Production")
    ) {
      return ["monthlyRate"];
    } else {
      return ["dailyRate"];
    }
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { object } = this.state;

    return (
      <div className="h-100" style={{ background: "white" }}>
        <NavBar />
        <Toaster />
        <div
          className="container-fluid"
          style={{
            paddingInline: "3rem",
            paddingBlock: "2rem",
            background: "white",
          }}
        >
          <div className="row align-items-center ">
            <i
              class="bi bi-chevron-left col-auto btn btn-link"
              onClick={this.onClickBack.bind(this)}
            ></i>
            <p className="fs-3 fw-bold col-auto m-0">Create New User</p>
          </div>
          <form onSubmit={this.onSubmitForm.bind(this)}>
            <CreateUserForm
              schema={schema}
              object={object}
              isEditing={this.isEditing()}
              onClickBack={this.onClickBack.bind(this)}
              onChange={this.onChange.bind(this)}
              excludedFields={[
                "id",
                "createdAt",
                "updatedAt",
                "acl",
                "username",
                "fullName",
                "status",
                "credits",
                // this.isEditing() && "password",
                // this.isEditing() && "confirmPassword",
                ...this.excludeRates(object),
              ]}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(UserFormPage);
