import React from "react";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
  getObjectUseCase,
} from "../../usecases/object";
import InvoicesPresenter from "./InvoicesPresenter";
import withRouter from "../../withRouter";
import InvoicesPrint from "./components/InvoicesPrint;";
import NavBar from "../../components/navbar";
import ReceiveInvoiceModal from "./components/modal/ReceiveInvoiceModal";
import { dialog, InfiniteScroll } from "nq-component";
import InvTable from "../../components/InvTable";
import {
  updateObjects,
  updateDueDates,
  filteredExclude,
  formatCurrency,
  calculateTotalCollectibles,
} from "./utils/utils";
import SummaryCard from "./components/SummaryCard";
import ActionButtons from "./components/ActionsButtons";
import FilterField from "./components/FilterField";
import ApproveDialogContent from "./components/ApproveDialogContent";
import StatusButtons from "./components/StatusButtons";

class InvoicesPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new InvoicesPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase(),
      getObjectUseCase()
    );
    this.state = {
      selected: [],
      objects: [],
      schema: [],
      modifiedObjects: [],
      dueDates: [],
      overdueDates: [],
      totalReceived: 0,
      services: [],
      progress: true,
      fetchobject: null,
      selectedClient: null,
      filteredProjects: [],
      witholdTax: false,
    };

    this.findObjectUseCase = findObjectUseCase();
    this.contractPDF = React.createRef();
  }

  getCollectionName() {
    return "invoices_final";
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    const { objects, isObjectsLoaded, modifiedObjects, dueDates } = this.state;

    if (objects.length > 0 && !isObjectsLoaded) {
      this.handleUpdateObjects();
    }

    if (
      modifiedObjects.length > 0 &&
      dueDates.length === 0 &&
      prevState.dueDates.length === 0
    ) {
      this.handleUpdateDueDates();
    }
  }

  handleUpdateObjects = () => {
    this.setState((prevState) => {
      const { modifiedObjects, totalReceived } = updateObjects(
        prevState.objects,
        prevState.totalReceived
      );

      if (
        JSON.stringify(modifiedObjects) !==
          JSON.stringify(prevState.modifiedObjects) ||
        totalReceived !== prevState.totalReceived
      ) {
        return { modifiedObjects, totalReceived, isObjectsLoaded: true };
      }
      return null;
    });
  };

  handleUpdateDueDates = () => {
    this.setState((prevState) => {
      const updatedDates = updateDueDates(prevState.modifiedObjects);

      if (
        JSON.stringify(updatedDates.dueDates) !==
          JSON.stringify(prevState.dueDates) ||
        JSON.stringify(updatedDates.overdueDates) !==
          JSON.stringify(prevState.overdueDates)
      ) {
        return updatedDates;
      }
      return null;
    });
  };

  onChange(value, field) {
    this.presenter.onChange2(value, field);
  }

  onChangeFilter(type, value, field) {
    this?.presenter?.onChangeFilter(type, value, field);
  }

  onClickExport() {
    this.presenter.onClickExport();
  }

  exportPDF() {
    this.presenter.exportPDF();
  }

  onClickDeleteSelected() {
    this.presenter.onClickDeleteSelected();
  }

  onClickApprove(index) {
    dialog.fire({
      html: (
        <ApproveDialogContent
          onApprove={() => {
            this.presenter.onClickApprove(index, "Approved");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  handleEdit(index) {
    this.presenter.handleEdit(index);
  }

  toggleModal(object, withhold) {
    this.setState((prev) => ({
      ...prev,
      modalOpen: !prev.modalOpen,
      selectedView: object?.id,
      withhold: withhold,
    }));
  }

  onCollapse(index, object) {
    return (
      <div className="d-flex">
        <StatusButtons
          index={index}
          object={object}
          onClickApprove={this.onClickApprove.bind(this)}
          handleEdit={this.handleEdit.bind(this)}
          toggleModal={this.toggleModal.bind(this)}
        />
      </div>
    );
  }

  handleSaveInvoice(
    index,
    object,
    accounts,
    obj,
    withhold,
    newAmount,
    withheldAmount
  ) {
    this.presenter.handleSubmit(
      index,
      object,
      this.state.change,
      accounts,
      obj,
      withhold,
      newAmount,
      withheldAmount
    );
  }

  render() {
    const {
      objects,
      selected,
      progress,
      totalReceived,
      fetchobject,
      dueDates,
      overdueDates,
    } = this.state;
    const user = this.getCurrentUser();
    const schema = this.getSchema(this.getCollectionName());

    const totalDueCollectibles = calculateTotalCollectibles(dueDates);
    const totalOverCollectibles = calculateTotalCollectibles(overdueDates);
    return (
      <>
        <div className="d-none">
          <div ref={this.contractPDF}>
            <InvoicesPrint
              fetchedObject={fetchobject}
              object={selected}
              services={selected[0]?.services}
            />
          </div>
        </div>
        <NavBar />

        <ReceiveInvoiceModal
          isOpen={this.state.modalOpen}
          toggleModal={this.toggleModal.bind(this)}
          onClickSave={this.handleSaveInvoice.bind(this)}
          obj={this.state.selectedView}
          withhold={this.state.withhold}
          onChange={this.onChange.bind(this)}
          onChangeAccount={(event) =>
            this.onChange(event.target.value, "account")
          }
          onChangePayment={(event) =>
            this.onChange(event.target.value, "payment_method")
          }
          change={this.state.change}
        />

        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || this.getCollectionName()}
                </h1>
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <SummaryCard
                  totalOverCollectibles={totalOverCollectibles}
                  totalDueCollectibles={totalDueCollectibles}
                  totalReceived={totalReceived}
                  formatCurrency={formatCurrency}
                />
                <ActionButtons
                  selected={this.state.selected}
                  user={user}
                  onClickExport={this.onClickExport.bind(this)}
                  onClickDeleteSelected={this.onClickDeleteSelected.bind(this)}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <FilterField
                        key={field}
                        field={field}
                        type={type}
                        options={options}
                        state={this.state}
                        onChangeFilter={this.onChangeFilter.bind(this)}
                      />
                    );
                  })}
                </div>
              </div>
              <InvTable
                fields={schema.fields}
                onCollapse={this.onCollapse.bind(this)}
                objects={objects}
                selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce((acc, key) => {
                  const options = schema.fields[key];
                  if (options.read === false) {
                    acc.push(key);
                  }
                  switch (options._type || options.type) {
                    case "Relation":
                    case "Array":
                    case "Object":
                    case "File":
                      acc.push(key);
                      break;
                    default:
                  }
                  return acc;
                }, filteredExclude())}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(InvoicesPage);
