export const updateObjects = (objects, currentTotalReceived) => {
  const currentDate = new Date();

  const modifiedObjects = objects.map((object) => {
    const dueDate = new Date(object.due_date);
    const differenceInDays = Math.ceil(
      (dueDate - currentDate) / (1000 * 3600 * 24)
    );

    return {
      ...object,
      due_date:
        differenceInDays < 0
          ? `Overdue by ${Math.abs(differenceInDays)} days`
          : differenceInDays === 0
          ? "Due today"
          : `Due in ${differenceInDays} days`,
    };
  });

  const totalReceived = objects.reduce(
    (acc, obj) => acc + (obj.received || 0),
    0
  );

  return {
    modifiedObjects,
    totalReceived:
      currentTotalReceived !== totalReceived
        ? totalReceived
        : currentTotalReceived,
  };
};

export const updateDueDates = (modifiedObjects) => {
  const dueDates = [];
  const overdueDates = [];

  modifiedObjects.forEach(({ due_date, collectibles }) => {
    if (due_date.startsWith("Due")) {
      dueDates.push({ due_date, collectibles });
    } else if (due_date.startsWith("Overdue")) {
      overdueDates.push({ due_date, collectibles });
    }
  });

  return { dueDates, overdueDates };
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(amount);
};

export const calculateTotalCollectibles = (datesArray) => {
  return datesArray.reduce((total, currentDate) => {
    const collectibles = currentDate.collectibles;

    if (Array.isArray(collectibles)) {
      return total + collectibles.reduce((acc, current) => acc + current, 0);
    } else {
      return total + collectibles;
    }
  }, 0);
};

export const filteredExclude = () => {
  const fields = [
    "acl",
    "exclusive_item",
    "estimateTotal",
    "estimate_name",
    "estimate_type",
    "estimate_num",
    "subtotal",
    "discount",
    "disc_type",
    "asf_type",
    "asf",
    "services",
    "name",
    "description",
    "qty",
    "rate",
    "password",
    "estimates",
    "client_email",
    "client_address",
    "notes",
    "po_num",
    "discountType",
    "asfType",
    "total",
    "exclusive",
    "due",
    "estimateId",
    "estimate_num",
    "nameService",
    "nameDescription",
    "descriptionArray",
    "downpaymentType",
    "downpaymentAmount",
    "downpayment",
    "withholdTax",
    "addVAT",
    "downpaymentValTax",
    "isWithHold",
  ];

  return fields;
};
