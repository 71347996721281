import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";
class TaskFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    console.log("this.change", this.change);
    try {
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    console.log(
      "find project",
      this.change.project,
      "find project",
      this.object.project,
      "find project",
      this.view.state.selectedProject,
      "find project",
      this.view.state.object.project
    );
    if (!this.change.project || this.object.project) {
      // this.change.project = this.view.state.selectedProject
      //   ? this.view.state.selectedProject
      //   : this.object.project;
      this.change.project = {
        id: this.view.state.selectedProject?.id ?? this.object.project.id,
      };
    }

    this.change.client = {
      id: this.change.client?.id ?? this.object.client.id,
    };
    this.change.type = {
      id: this.change.type?.id ?? this.object.type.id,
    };
    if (!this.change.statuses || !this.object.statuses) {
      this.change.statuses = "In Progress";
    }
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Data has been successfully saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      // await this.view.navigateTo("/tasks");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default TaskFormPresenter;
