import React from "react";
import InputFactory from "../../../components/InputFactory";
// Ensure correct import

const FilterField = ({ field, type, options = {}, state, onChangeFilter }) => {
  // Handle project_name dropdown
  if (field === "project_name") {
    const isDisabled = !state.selectedClient;

    return (
      <select
        className="form-select ms-1"
        onChange={(e) =>
          onChangeFilter("Pointer", { id: e.target.value }, "project_name")
        }
        disabled={isDisabled}
      >
        <option value="">
          {isDisabled ? "Please select a client first" : "Select a project"}
        </option>
        {state.filteredProjects?.map((project) => (
          <option key={project.id} value={project.id}>
            {project.name}
          </option>
        ))}
      </select>
    );
  }

  // Handle client_name with InputFactory
  if (field === "client_name") {
    return (
      <InputFactory
        key={field}
        className="ms-1"
        type={type}
        field={field}
        where={{}}
        onChange={onChangeFilter.bind(null, type)}
        {...options}
      />
    );
  }

  return null; // Ensure no unwanted rendering
};

export default FilterField;
