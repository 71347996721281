import React from "react";

const ActionButtons = ({
  selected,
  user,
  onClickExport,
  onClickDeleteSelected,
}) => {
  return (
    <div className="d-flex align-items-end">
      {selected?.length === 1 && (
        <button onClick={onClickExport} className="btn bg-sec me-2 px-3 py-2">
          <i className="bi bi-download me-2"></i>Download PDF
        </button>
      )}
      {user.roles.some(
        (role) => role.id === "masterAdmin" || role.id === "admin"
      ) &&
        selected.length > 0 && (
          <button
            className="btn btn-danger me-2 px-3 py-2"
            onClick={onClickDeleteSelected}
          >
            <i className="bi bi-trash"></i> Delete
          </button>
        )}
    </div>
  );
};

export default ActionButtons;
