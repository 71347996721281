import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../../usecases/object";
import { getDateRange } from "../utils/getDateRange";

class PayrollFormPresenter extends BaseFormPresenter {
  formatDateForQuery(dateInput) {
    const date = new Date(dateInput);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  computeWorkDayByHours(hours) {
    hours = parseFloat(hours);
    let day = Math.floor(hours / 8);

    return day;
  }

  getRoleId(name, roles) {
    return roles.find((role) => role.id === name)?.id;
  }

  async createDefaults(payroll) {
    const { id, type, startDate, endDate } = payroll;
    const roles = await findObjectUseCase().execute("roles");

    if (type === "Weekly") {
      let total = 0;
      let totalDaysWorked = 0;
      const dateRange = getDateRange(startDate, endDate).slice(0, 7);

      const users = await findObjectUseCase().execute("users", {
        where: {
          "roles.id": {
            $in: [
              this.getRoleId("Production", roles),
              this.getRoleId("user", roles),
            ],
          },
          status: "Active",
        },
      });

      const totalSum = users.reduce(
        (acc, user) => acc + (user.dailyRate || 0),
        0
      );

      payroll.total = totalSum;
      console.log("halaga", payroll);

      // await this.upsertUseCase.execute("payroll", payroll);

      users.forEach(async (user) => {
        const leaves = await findObjectUseCase().execute("leave_request", {
          where: {
            name: { id: user.id },
            statuses: "Paid",
          },
          include: ["type", "name"],
        });

        dateRange.forEach((date) => {
          const currentDate = new Date(date);
          for (const leaveRequest of leaves) {
            const leaveStartDate = new Date(Date.parse(leaveRequest.startDate));
            const leaveEndDate = new Date(Date.parse(leaveRequest.endDate));
            if (currentDate >= leaveStartDate && currentDate <= leaveEndDate) {
              total +=
                (leaveRequest.isHalfday ? 4 : 8) * ((user.dailyRate || 0) / 8);
              totalDaysWorked += leaveRequest.isHalfday ? 4 : 8;
            }
          }
        });

        await this.upsertUseCase.execute("employee_payroll_info", {
          userId: user.id,
          payrollId: id,
          daysWorked: this.computeWorkDayByHours(totalDaysWorked),
          subtotal: total,
          deductions: 0,
          total,
        });
      });
    } else {
      const allowedRoles = [
        this.getRoleId("Designer", roles),
        this.getRoleId("Operation Manager", roles),
        this.getRoleId("Sales", roles),
        this.getRoleId("admin", roles),
        this.getRoleId("hrAdmin", roles),
        this.getRoleId("masterAdmin", roles),
      ];
      const users = await findObjectUseCase().execute("users", {
        where: {
          "roles.id": {
            $in: allowedRoles,
            $nin: [
              this.getRoleId("Production", roles),
              this.getRoleId("user", roles),
            ],
          },
          status: "Active",
        },
      });
      console.log("user monthlysss", users);
      const totalSum = users.reduce(
        (acc, user) => acc + (user.monthlyRate || 0),
        0
      );

      payroll.total = totalSum / 2;
      console.log("halaga", payroll);

      await this.upsertUseCase.execute("payroll", payroll);

      users.forEach(async (user) => {
        const res = await this.upsertUseCase.execute("employee_payroll_info", {
          userId: user?.id,
          payrollId: id,
          daysWorked: 0,
          deductions: 0,
          subtotal: 0,
          total: user?.monthlyRate / 2,
        });

        console.log("RES", res);
      });
    }
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }

    try {
      const payroll = await this.upsertUseCase.execute(collection, this.change);

      console.log("MILO", payroll);

      if (!this.view.getParams().id) {
        await this.createDefaults(payroll);
      }

      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    if (this.view.state.object.type === "Weekly") {
      this.object = {
        ...this.object,
        startDate: new Date(this.object.startDate),
        endDate: new Date(this.getDateAfterSevenDays(this.object.startDate)),
      };
      this.change = this.object;
    } else {
      this.object = {
        ...this.object,
        startDate: new Date(this.object.startDate),
        endDate: new Date(this.object.endDate),
      };
      this.change = this.object;
    }

    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }

    try {
      this.view
        .showAddPayrollDialog()
        .then(async () => {
          this.view.submitting();
          await this.save();
          this.view.submissionSuccess();
          this.view.showToast("Payroll has been successfully added.");
          this.view.navigateBack();
        })
        .catch((error) => console.log(error));
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  getDateAfterSevenDays(dateString) {
    var dateParts = dateString.split("-");
    var year = parseInt(dateParts[0]);
    var month = parseInt(dateParts[1]) - 1;
    var day = parseInt(dateParts[2]);

    var inputDate = new Date(year, month, day);

    var newDate = new Date(inputDate);
    newDate.setDate(newDate.getDate() + 6);

    var newYear = newDate.getFullYear();
    var newMonth = newDate.getMonth() + 1;
    var newDay = newDate.getDate();

    if (newMonth < 10) {
      newMonth = "0" + newMonth;
    }
    if (newDay < 10) {
      newDay = "0" + newDay;
    }

    return newYear + "-" + newMonth + "-" + newDay;
  }
}

export default PayrollFormPresenter;
