import { dialog } from "nq-component";
import BaseListPresenter from "../../base/BaseListPresenter";
import classNames from "../../classNames";
import { parse } from "papaparse";

class PayablesPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase,
    getObjectUseCase,
    updateObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase,
      getObjectUseCase,
      updateObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
    this.getObjectUseCase = getObjectUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
  }

  init() {
    this.limit = 100;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filter2 = {};
    this.filter3 = {};
    this.filterDate = {};
    this.filterEmployee = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }
  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filter2,
        ...this.filterDate,
        ...this.filter3,
        statuses: "Pending",
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  filterSubmit2(where) {
    this.reset();
    this.filter2 = where;
    this.getObjects();
  }

  filterSubmit3(where) {
    this.reset();
    this.filter3 = where;
    this.getObjects();
  }
  filterSubmit10(where) {
    this.reset();
    this.filter10 = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeEmployee(where) {
    this.filterEmployee = where;
    this.getObjects();
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    // this.view.navigateTo("/form/expenses/" + collection);
    this.view.navigateTo("/form/expenses");
  }

  async onClickStatus(index, status) {
    const collection = "budget_request";
    const object = this.objects[index];

    object.statuses =
      status === "approved"
        ? "approved"
        : status === "paid"
        ? "paid"
        : status === "disapproved"
        ? "disapproved"
        : "liquidation";

    try {
      const data = await this.upsertUseCase.execute(collection, object);

      if (data.statuses === object.statuses) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
        alert("hi");

        if (data.statuses === "paid") {
          // Perform an additional upsert operation to the 'expenses' collection
          const expensesCollection = "expense";
          await this.upsertUseCase.execute(expensesCollection, object);
        }
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  onClickLiquidate(index) {
    const object = this.objects[index];
    this.view.navigateTo("/liquidate/form/" + object.id);
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    // this.view.navigateTo("/collection/" + collection + "/form/" + object.id);
    this.view.navigateTo("/form/expenses/" + object.id);
  }
  async onClickPaid(index, objects) {
    const object = this.objects[index];
    console.warn("oahaha", objects);
    const expenses = {};
    const id = object.id;
    const purchaseOrders = await this.findObjectUseCase.execute(
      "purchase_orders"
    );
    const gmd_accounts = await this.findObjectUseCase.execute("gmd_accounts");
    const purchaseOrderObject = purchaseOrders.find(
      (order) => order.po_num === object.purchaseOrderNumber
    );
    console.warn("purchaseObject", purchaseOrderObject);

    const objectobj = objects[index]?.pending_balance;
    const purcObj = {
      pending_balance: 0,
      id: objects[index].id,
      statuses: "Paid",
    };
    if (purchaseOrderObject && purchaseOrderObject.po_num) {
      purcObj.po_num = purchaseOrderObject.po_num;
    }
    console.warn("purchase", purchaseOrders);

    const payablesCollection = "payables";
    expenses.project_name = { id: object.project_name.id };
    expenses.client_name = { id: object.client_name.id };
    expenses.accounts = { id: object.accounts.id };
    expenses.amount = objects[index]?.pending_balance;
    expenses.due_date = object.due_date;
    expenses.items = object.items;
    expenses.purchaseOrderNumber = object.purchaseOrderNumber;
    expenses.remarks = object.remarks;
    expenses.chart_accounts = objects[index].chart_accounts;

    object.statuses = "Paid";

    // const data = {...expenses, project_name: object.project.name, client_name: object.client_name;}

    if (object.amount > object.accounts.balance) {
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames("bi bi-x-circle", "text-warning")}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Failed!</h4>
              <p className="m-0">
                Insufficient balance. Transaction cannot be processed.
              </p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
    } else {
      const updatedBalance = object.accounts.balance - object.amount;
      // console.log("OCA", object.accounts.balance);
      // console.log("OA", object.amount);
      // console.log("UUUUUUU", updatedBalance);

      // const updatedBalances = gmdFundObject.balance - object.amount;

      // gmdFundObject.balance = updatedBalance;

      const originalObject = {
        note: "Purchase Orders",
        amount: object.amount,
        types: {
          type: "Money Out",
        },

        account: {
          name: "GMD Revolving Fund",
        },

        account_balance: updatedBalance,
      };

      console.warn("gmd_accounts", gmd_accounts);

      // const accountIds = {
      //   BPI: "880add74-8663-4378-a05f-cfb84aaefd2d",
      //   "BDO Main": "846e6ea9-e9af-434d-9a09-d950a53ed74a",
      //   "GMD Revolving Fund": "79f9b3c7-84b1-4a95-9530-efd31953ef72",
      //   Metrobank: "641c215e-c184-45ea-81ca-1f704b009a9f",
      //   "GMD Fund": "d0210284-95ec-4b35-a9af-4dc5bdef3983",
      //   "Management money": "ae8d75c4-756e-4630-8357-cac7db7a3c83",
      //   // ... add other accounts as needed
      // };

      const accountIds = gmd_accounts.reduce((acc, account) => {
        acc[account.name] = account.id;
        return acc;
      }, {});

      console.log("Dynamic accountIds:", accountIds);

      // gmd_accounts

      // console.warn("this.change", this.change);

      // Function to get account ID by name
      function getAccountId(accountName) {
        return accountIds[accountName] || null; // returns null if the account name is not found
      }

      // Usage
      const accountName = object.accounts?.name; // Get the account name dynamically
      const accountId = getAccountId(accountName); // Get the corresponding account ID
      console.warn("accountid", accountId);
      // Storing in obAccount
      const obAccount = {
        id: accountId, // This will be the ID corresponding to the account name
        name: accountName,
      };

      // const obAccount = {
      //   id: "79f9b3c7-84b1-4a95-9530-efd31953ef72",
      //   name: "GMD Revolving Fund",
      // };
      const obTypes = {
        id: "f3a5c4d2-cbd0-4305-9835-5c8c75de59c4",
        type: "Money Out",
      };
      const cashInBank = await this.findObjectUseCase.execute("gmd_accounts");
      const combineBal = cashInBank.reduce(
        (total, account) => total + account.balance,
        0
      );

      const desiredObject = {
        note: "Payables",
        // account: obAccount,
        account: { id: object.accounts.id },
        account_balance: combineBal - objects[index]?.pending_balance,
        types: { id: obTypes.id },
        amounts: String(objects[index]?.pending_balance),
        project_name: { id: expenses.project_name.id },
        clients: objects[index].client_name?.name,
        chart_of_accounts: objects[index].chart_accounts,
      };
      // console.warn(desiredObject)

      // const trans = await this.upsertUseCase.execute(
      //   "transaction",
      //   desiredObject
      // );

      // const updatedBalance = object.accounts.balance - object.amount;
      //   const accObj = {
      //     name:objects[index]?.accounts.name,
      //     balance:objects[index]?.accounts.balance - inputMoney,
      //     id:objects[index].accounts?.id
      // }

      const updatedAccountsObject = {
        ...object.accounts,
        balance:
          objects[index]?.accounts.balance - objects[index]?.pending_balance,
        id: objects[index].accounts?.id,
      };

      object.accounts = { id: object.accounts.id };
      object.client_name = { id: object.client_name.id };
      object.project_name = { id: object.project_name.id };
      object.vendor_name = { id: object.vendor_name.id };
      console.warn("this.change.trans", desiredObject);
      console.warn("this.change.updategmd", updatedAccountsObject);
      console.warn("this.change.expense", expenses);
      console.warn("this.change.payables", object);

      await this.upsertUseCase.execute("transaction", desiredObject);
      await this.upsertUseCase.execute("gmd_accounts", updatedAccountsObject);
      await this.upsertUseCase.execute("expense", expenses);
      await this.upsertUseCase.execute(payablesCollection, object);
      {
        objects[index]?.purchaseOrderNumber &&
          (await this.upsertUseCase.execute("purchase_orders", purcObj));
      } //here prob
      this.getObjects();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Item Saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
    }
  }
  async onClickPartPaid(index, objects) {
    console.warn("objects", this.objects);
    //console.warn(this.getCurrentDateFormatted())
    const cashInBank = await this.findObjectUseCase.execute("gmd_accounts");
    const combineBal = cashInBank.reduce(
      (total, account) => total + account.balance,
      0
    );
    dialog.fire({
      html: (
        <div className="text-start">
          <div className="text-start p-4">
            <p className="mb-2">Amount:</p>
            <input
              type="text"
              className="form-control mb-3"
              id="inputField"
              placeholder="Enter your input"
              pattern="[0-9]*\.?[0-9]*"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.]/g, "");
              }}
            />
            <div className="text-end">
              <button
                className="btn btn-secondary mb-2 me-2"
                style={{ backgroundColor: "#f5f5f5", color: "#333" }}
                onClick={() => dialog.close()}
              >
                Cancel
              </button>

              <button
                className="btn btn-primary mb-2"
                onClick={async () => {
                  const inputMoney =
                    document.getElementById("inputField").value;
                  const objectobj =
                    objects[index]?.pending_balance - inputMoney;

                  if (objectobj < 0) {
                    dialog.fire({
                      html: (
                        <div className="text-end">
                          <div className="text-center p-4">
                            <i
                              className={classNames(
                                "bi bi-x-circle",
                                "text-warning"
                              )}
                              style={{ fontSize: "5rem" }}
                            ></i>
                            <h4 className="fw-bold">Failed!</h4>
                            <p className="m-0">
                              Invalid Input. Transaction cannot be processed.
                            </p>
                            <button
                              className="btn mb-2 mt-2 me-2"
                              style={{ backgroundColor: "#EBBD2F" }}
                              onClick={() => {
                                dialog.close();
                                this.view.navigateTo("/payables");
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      ),
                      footer: false,
                    });
                  }
                  const newpart = objectobj;
                  const obTypes = {
                    id: "f3a5c4d2-cbd0-4305-9835-5c8c75de59c4",
                    type: "Money Out",
                  };

                  const desiredObjects = {
                    pending_balance: newpart,
                    project_name: { id: objects[index].project_name.id },
                    client_name: { id: objects[index].client_name.id },
                    items: objects[index].items,
                    due_date: objects[index].due_date,
                    //amount:objects[index]?.pending_balance,
                    accounts: { id: objects[index].accounts.id },
                    id: objects[index].id,
                  };
                  const transObj = {
                    project_name: { id: objects[index].project_name.id },
                    clients: objects[index].client_name?.name,
                    // createdAt: this.getCurrentDateFormatted(),
                    amounts: inputMoney,
                    account: { id: objects[index].accounts.id },
                    account_balance: combineBal - inputMoney,
                    chart_of_accounts: objects[index].chart_accounts,
                    types: { id: obTypes.id },
                    note: "payables",
                    // id:cashInBank.id
                  };

                  const expObj = {
                    project_name: { id: objects[index].project_name.id },
                    client_name: { id: objects[index].client_name.id },
                    chart_accounts: objects[index].chart_accounts,
                    due_date: this.getCurrentDateFormatted(),
                    amount: parseFloat(inputMoney),
                    accounts: { id: objects[index].accounts.id },
                    items: objects[index]?.items,
                    purchaseOrderNumber: objects[index].purchaseOrderNumber,
                  };
                  const accObj = {
                    name: objects[index]?.accounts.name,
                    balance: objects[index]?.accounts.balance - inputMoney,
                    id: objects[index].accounts?.id,
                  };
                  const purcObj = {
                    pending_balance: newpart === 0 ? 0 : newpart,
                    id: objects[index].id,
                    statuses: newpart === 0 ? "Paid" : "Partially Paid",
                  };

                  console.warn("send Transaction", transObj);
                  console.warn("send account", accObj);
                  console.warn("send purchase", purcObj);
                  console.warn("send expense", expObj);
                  console.warn("send po", desiredObjects);

                  if (
                    !desiredObjects.pending_balance ||
                    desiredObjects.pending_balance === 0
                  ) {
                    dialog.fire({
                      html: (
                        <div className="text-end">
                          <div className="text-center p-4">
                            <i
                              className={classNames(
                                "bi bi-cash",
                                "text-warning"
                              )}
                              style={{ fontSize: "5rem" }}
                            ></i>
                            <h4 className="fw-bold">Success!</h4>
                            <p className="m-0">Fully Paid </p>
                            <button
                              className="btn mb-2 mt-2 me-2"
                              style={{ backgroundColor: "#EBBD2F" }}
                              onClick={async () => {
                                await this.updateObjectUseCase.execute(
                                  "gmd_accounts",
                                  accObj
                                );
                                await this.upsertUseCase.execute(
                                  "expense",
                                  expObj
                                );
                                await this.upsertUseCase.execute(
                                  "transaction",
                                  transObj
                                );
                                {
                                  objects[index]?.purchaseOrderNumber &&
                                    (await this.upsertUseCase.execute(
                                      "purchase_orders",
                                      purcObj
                                    ));
                                }
                                await this.deleteObjectUseCase.execute(
                                  "payables",
                                  desiredObjects.id
                                );
                                this.view.navigateTo("/payables");
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      ),
                      footer: false,
                    });
                  } else if (desiredObjects.pending_balance >= 1) {
                    dialog.fire({
                      html: (
                        <div className="text-end">
                          <div className="text-center p-4">
                            <i
                              className={classNames(
                                "bi bi-cash",
                                "text-warning"
                              )}
                              style={{ fontSize: "5rem" }}
                            ></i>
                            <h4 className="fw-bold">Success!</h4>
                            <p className="m-0">Partially Paid ₱{inputMoney}</p>
                            <button
                              className="btn mb-2 mt-2 me-2"
                              style={{ backgroundColor: "#EBBD2F" }}
                              onClick={async () => {
                                await this.updateObjectUseCase.execute(
                                  "gmd_accounts",
                                  accObj
                                );
                                await this.upsertUseCase.execute(
                                  "expense",
                                  expObj
                                );
                                await this.upsertUseCase.execute(
                                  "transaction",
                                  transObj
                                );
                                {
                                  objects[index]?.purchaseOrderNumber &&
                                    (await this.upsertUseCase.execute(
                                      "purchase_orders",
                                      purcObj
                                    ));
                                }

                                this.view.navigateTo("/payables");
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      ),
                      footer: false,
                    });
                  }
                  if (objectobj >= 0) {
                    await this.upsertUseCase.execute(
                      "payables",
                      desiredObjects
                    );
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ),
      footer: false,
    });
  }
}

export default PayablesPresenter;
