// import BaseFormPresenter from "../../../base/BaseFormPresenter";
// import { findObjectUseCase } from "../../../usecases/object";
// import { dialog } from "nq-component";
// import classNames from "../../../classNames";

// class EventPresenter extends BaseFormPresenter {
//   getCurrentDateFormatted() {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, "0");
//     const day = String(now.getDate()).padStart(2, "0");

//     return `${year}-${month}-${day}`;
//   }

//   componentDidMount() {
//     this.init();

//     this.displayData();
//     this.getObject();
//   }

//   async getObject() {
//     const collection = this.view.getCollectionName();
//     const id = this.object.id || this.view.getObjectId();
//     console.log("ididd", id);
//     if (id) {
//       const params = {
//         include: [
//           "client_name",
//           "name",
//           "services",
//           "services.questions",
//           "services.questions.service",
//           "project_name",
//         ],
//       };
//       try {
//         this.view.showProgress();
//         this.object = await this.getObjectUseCase.execute(collection, id, {
//           params,
//         });
//         console.log("ovovov", this.object);
//         this.view.hideProgress();
//         this.view.setObject(this.object);
//       } catch (error) {
//         this.view.hideProgress();
//         this.view.showError(error);
//       }
//     }
//   }

//   displayData() {
//     const collection = this.view.getCollectionName();
//     const exam = this.view.getExamId();
//     const query = {
//       where: { id: exam?.id },
//       include: [
//         "All",
//         "services",
//         "services.questions",
//         // "categories.questions.answers",
//         ,
//       ],
//     };
//     console.log("query", query);
//     if (exam?.id) {
//       this.view.showProgress();
//       this.findObjectUseCase
//         .execute(collection, query)
//         .then(([object]) => {
//           this.view.hideProgress();
//           this.object = object;
//           console.log("edit", this.object);
//           this.view.setObject(Object.assign({}, object));
//         })
//         .catch((error) => {
//           this.view.hideProgress();
//           this.view.showError(error);
//         });
//     }
//   }

//   getCurrentDateFormatted() {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, "0");
//     const day = String(now.getDate()).padStart(2, "0");

//     return `${year}-${month}-${day}`;
//   }

//   onChangeObject(object) {
//     this.change = object;
//     this.object = object;
//   }
//   async approved() {
//     const collection = "estimate_forms";
//     const id = this.object.id;
//     const approved = "Approved";
//     const approveAt = this.getCurrentDateFormatted();
//     console.log("Approve Date:", approveAt);

//     const objectToUpsert = {
//       id: id,
//       statuses: approved,
//       approveAt: approveAt,
//     };

//     await this.upsertUseCase.execute(collection, objectToUpsert);

//     this.view.showSuccessSnackbar("Successfully Approved!");
//     await this.view.navigateTo("/estimate");
//   }
//   async save(updatedObject) {
//     const collection = "estimate_forms";

//     try {
//       if (Object.values(this.change).length === 0) {
//         if (typeof updatedObject.subTotal === "string") {
//           updatedObject.subTotal = parseFloat(updatedObject.subTotal);
//         }
//         if (typeof updatedObject.taxAmount === "string") {
//           updatedObject.taxAmount = parseFloat(updatedObject.taxAmount);
//         }
//         if (typeof updatedObject.total === "string") {
//           updatedObject.total = parseFloat(updatedObject.total);
//         }

//         await this.upsertUseCase.execute(collection, updatedObject);
//         this.view.showSuccessSnackbar("Changes saved!");
//         this.view.navigateTo("/estimate");

//         return;
//       }

//       const updatedChange = {};
//       const { client_name, project_name, discount, ASF } = this.change;
//       if (client_name) {
//         updatedChange.client_name = updatedObject.client_name;
//       }
//       if (project_name) {
//         updatedChange.project_name = updatedObject.project_name;
//       }
//       if (discount) {
//         updatedChange.discount = updatedObject.discount;
//       }
//       if (ASF) {
//         updatedChange.ASF = updatedObject.ASF;
//       }

//       if (Object.keys(updatedChange).length > 0) {
//         const updatedObjectWithChanges = {
//           ...updatedObject,
//           ...updatedChange,
//         };

//         if (typeof updatedObjectWithChanges.subTotal === "string") {
//           updatedObjectWithChanges.subTotal = parseFloat(
//             updatedObjectWithChanges.subTotal
//           );
//         }
//         if (typeof updatedObjectWithChanges.taxAmount === "string") {
//           updatedObjectWithChanges.taxAmount = parseFloat(
//             updatedObjectWithChanges.taxAmount
//           );
//         }
//         if (typeof updatedObjectWithChanges.total === "string") {
//           updatedObjectWithChanges.total = parseFloat(
//             updatedObjectWithChanges.total
//           );
//         }

//         await this.upsertUseCase.execute(collection, updatedObjectWithChanges);
//         this.view.showSuccessSnackbar("Changes saved!");
//         this.view.navigateTo("/estimate");
//       }
//     } catch (error) {
//       this.view.submissionError(error);
//       this.view.showError(error);
//     }
//   }

//   async onClickApproved() {
//     const collection = "services_final";
//     const id = this.object.id;
//     const approved = "Approved";
//     const object = this.view.getObject();
//     // const approveAt = this.getCurrentDateFormatted();

//     const objectToUpsert = {
//       id: id,
//       statuses: approved,
//       // approveAt: approveAt
//     };
//     const query = {
//       where: {
//         id: object.project_name?.id,
//         client: { id: object.client_name?.id },
//       },
//     };
//     const projectIP = await findObjectUseCase().execute("projects", query);

//     if (projectIP.length < 1) {
//       this.view.showSuccessSnackbar("Successfully Approved!");
//       this.view.navigateTo("/estimate");
//       await this.upsertUseCase.execute(collection, objectToUpsert);
//       return;
//     }

//     const projectObjectToUpsert = {
//       id: projectIP[0]?.id,
//       statuses: "In Progress",
//       // approveAt: approveAt
//     };
//     console.log("projectIPP", projectObjectToUpsert);

//     await this.upsertUseCase.execute(collection, objectToUpsert);

//     if (
//       projectIP[0]?.statuses !== "In Progress" &&
//       projectIP[0]?.statuses !== "Done"
//     ) {
//       await this.upsertUseCase.execute("projects", projectObjectToUpsert);
//     }

//     this.view.showSuccessSnackbar("Successfully Approved!");
//     await this.view.navigateTo("/estimate");
//   }
//   async submit() {
//     const estimate = await findObjectUseCase().execute("services_final");
//     const queryPID = {
//       where: {
//         id: this.view.props.params.pid,
//       },
//     };
//     const queryCID = {
//       where: {
//         id: this.view.props.params.cid,
//       },
//     };

//     const pid = await findObjectUseCase().execute("projects", queryPID);
//     const cid = await findObjectUseCase().execute("clients", queryCID);

//     let highestEstNo = 0;
//     estimate.forEach((item) => {
//       if (item.estimate_num && item.estimate_num > highestEstNo) {
//         highestEstNo = item.estimate_num;
//       }
//     });
//     const object = this.view.getObject();

//     const collection = "services_final";
//     if (this.view.props.params.pid && this.view.props.params.cid) {
//       object.project_name = pid[0];
//       object.client_name = cid[0];
//     } else if (this.change.project_name || !this.object.project_name) {
//       object.project_name = this.view.state.selectedProject
//         ? this.view.state.selectedProject
//         : this.object.project_name;
//     }
//     object.downpaymentVal = this.view.state.downpaymentVal || 0;
//     object.statuses = "Pending";
//     object.estimate_type = "Event";
//     object.estimate_num = this.view.getParams().hasOwnProperty("id")
//       ? highestEstNo
//       : highestEstNo + 1;
//     object.asf = this.view.state.object.asf || 0;
//     object.discount = this.view.state.object.discount || 0;

//     let updatedDescriptions = sessionStorage.getItem("updatedDescriptions");

//     if (updatedDescriptions) {
//       // Parse the JSON string into an array of objects
//       updatedDescriptions = JSON.parse(updatedDescriptions);

//       // Map over the array to extract the descriptions and categoryIndex
//       object.descriptionArray = updatedDescriptions.map((desc) => {
//         return {
//           description: desc.description,
//           categoryIndex: desc.categoryIndex,
//         };
//       });

//       console.log("UD", updatedDescriptions);
//     } else {
//       console.log("No updatedDescriptions found in sessionStorage");
//     }

//     console.log("final send", object);

//     await this.upsertUseCase.execute(collection, object);
//     this.view.submissionSuccess();
//     this.view.showSuccessSnackbar("Successfully saved!");
//     this.view.navigateBack();
//   }
//   async cancel() {
//     dialog.fire({
//       html: (
//         <div className="text-end">
//           <div className="text-center p-4">
//             <i
//               className={classNames("bi bi-emoji-frown", "text-danger")}
//               style={{ fontSize: "5rem" }}
//             ></i>
//             <h4 className="fw-bold">Cancelled!</h4>
//             <p className="m-0">Operation has been cancelled.</p>
//             <button
//               className="btn mb-2 mt-2 me-2"
//               style={{ backgroundColor: "#EBBD2F" }}
//               onClick={() => dialog.close()}
//             >
//               Okay
//             </button>
//           </div>
//         </div>
//       ),
//       footer: false,
//     });

//     await this.view.navigateTo("/estimate");
//   }
// }

// export default EventPresenter;

import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../../usecases/object";
import { dialog } from "nq-component";
import classNames from "../../../classNames";

class EventPresenter extends BaseFormPresenter {
  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  componentDidMount() {
    this.init();

    this.displayData();
    this.getObject();
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    console.log("ididd", id);
    if (id) {
      const params = {
        include: [
          "client_name",
          "name",
          "services",
          "services.questions",
          "services.questions.service",
          "project_name",
        ],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        console.log("ovovov", this.object);
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  displayData() {
    const collection = this.view.getCollectionName();
    const exam = this.view.getExamId();
    const query = {
      where: { id: exam?.id },
      include: [
        "All",
        "services",
        "services.questions",
        // "categories.questions.answers",
        ,
      ],
    };
    console.log("query", query);
    if (exam?.id) {
      this.view.showProgress();
      this.findObjectUseCase
        .execute(collection, query)
        .then(([object]) => {
          this.view.hideProgress();
          this.object = object;
          console.log("edit", this.object);
          this.view.setObject(Object.assign({}, object));
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }
  async approved() {
    const collection = "estimate_forms";
    const id = this.object.id;
    const approved = "Approved";
    const approveAt = this.getCurrentDateFormatted();
    console.log("Approve Date:", approveAt);

    const objectToUpsert = {
      id: id,
      statuses: approved,
      approveAt: approveAt,
    };

    await this.upsertUseCase.execute(collection, objectToUpsert);

    this.view.showSuccessSnackbar("Successfully Approved!");
    await this.view.navigateTo("/estimate");
  }
  async save(updatedObject) {
    const collection = "estimate_forms";

    try {
      if (Object.values(this.change).length === 0) {
        if (typeof updatedObject.subTotal === "string") {
          updatedObject.subTotal = parseFloat(updatedObject.subTotal);
        }
        if (typeof updatedObject.taxAmount === "string") {
          updatedObject.taxAmount = parseFloat(updatedObject.taxAmount);
        }
        if (typeof updatedObject.total === "string") {
          updatedObject.total = parseFloat(updatedObject.total);
        }
        if (typeof updatedObject.estimateTotal === "string") {
          updatedObject.estimateTotal = parseFloat(updatedObject.estimateTotal);
        }

        await this.upsertUseCase.execute(collection, updatedObject);
        this.view.showSuccessSnackbar("Changes saved!");
        this.view.navigateTo("/estimate");

        return;
      }

      const updatedChange = {};
      const { client_name, project_name, discount, ASF } = this.change;
      if (client_name) {
        updatedChange.client_name = updatedObject.client_name;
      }
      if (project_name) {
        updatedChange.project_name = updatedObject.project_name;
      }
      if (discount) {
        updatedChange.discount = updatedObject.discount;
      }
      if (ASF) {
        updatedChange.ASF = updatedObject.ASF;
      }

      if (Object.keys(updatedChange).length > 0) {
        const updatedObjectWithChanges = {
          ...updatedObject,
          ...updatedChange,
        };

        if (typeof updatedObjectWithChanges.subTotal === "string") {
          updatedObjectWithChanges.subTotal = parseFloat(
            updatedObjectWithChanges.subTotal
          );
        }
        if (typeof updatedObjectWithChanges.taxAmount === "string") {
          updatedObjectWithChanges.taxAmount = parseFloat(
            updatedObjectWithChanges.taxAmount
          );
        }
        if (typeof updatedObjectWithChanges.total === "string") {
          updatedObjectWithChanges.total = parseFloat(
            updatedObjectWithChanges.total
          );
        }

        if (typeof updatedObjectWithChanges.estimateTotal === "string") {
          updatedObjectWithChanges.estimateTotal = parseFloat(
            updatedObjectWithChanges.estimateTotal
          );
        }

        await this.upsertUseCase.execute(collection, updatedObjectWithChanges);
        this.view.showSuccessSnackbar("Changes saved!");
        this.view.navigateTo("/estimate");
      }
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  async onClickApproved() {
    const collection = "services_final";
    const id = this.object.id;
    const approved = "Approved";
    const object = this.view.getObject();
    // const approveAt = this.getCurrentDateFormatted();

    const objectToUpsert = {
      id: id,
      statuses: approved,
      // approveAt: approveAt
    };
    const query = {
      where: {
        id: object.project_name?.id,
        client: { id: object.client_name?.id },
      },
    };
    const projectIP = await findObjectUseCase().execute("projects", query);

    object.services = object.services.filter(
      (service) => service.__operation !== "REMOVE"
    );

    let updatedDescriptions = sessionStorage.getItem("updatedDescriptions");
    if (updatedDescriptions) {
      // Parse the JSON string into an array of objects
      updatedDescriptions = JSON.parse(updatedDescriptions);

      const query = {
        where: {
          id: object.id,
        },
      };

      const response = await findObjectUseCase().execute(collection, query);
      console.log("response: ", response);

      // Initialize the descriptionArray if it's not already there
      if (!object.descriptionArray) {
        object.descriptionArray = [];
      }

      // Iterate over updatedDescriptions and update the corresponding descriptionArray entry based on categoryIndex
      updatedDescriptions.forEach((updatedDesc) => {
        // Find the matching categoryIndex in the existing descriptionArray
        const existingDescIndex = object.descriptionArray.findIndex(
          (desc) => desc.categoryIndex === updatedDesc.categoryIndex
        );

        if (existingDescIndex !== -1) {
          // Update the existing description entry
          object.descriptionArray[existingDescIndex].description =
            updatedDesc.description;
        } else {
          // If categoryIndex doesn't exist, add the new description entry
          object.descriptionArray.push({
            description: updatedDesc.description,
            categoryIndex: updatedDesc.categoryIndex,
          });
        }
      });

      console.log("Updated DescriptionArray", object.descriptionArray);
    } else {
      console.log("No updatedDescriptions found in sessionStorage");
    }

    // Filter out deleted descriptions from descriptionArray
    object.descriptionArray = object.descriptionArray.filter(
      (desc) => !desc.__operation || desc.__operation !== "REMOVE"
    );

    console.log("final send", object);

    await this.upsertUseCase.execute(collection, object);

    if (projectIP.length < 1) {
      this.view.showSuccessSnackbar("Successfully Approved!");
      this.view.navigateTo("/estimate");
      await this.upsertUseCase.execute(collection, objectToUpsert);
      return;
    }

    const projectObjectToUpsert = {
      id: projectIP[0]?.id,
      statuses: "In Progress",
      // approveAt: approveAt
    };
    console.log("projectIPP", projectObjectToUpsert);

    await this.upsertUseCase.execute(collection, objectToUpsert);

    if (
      projectIP[0]?.statuses !== "In Progress" &&
      projectIP[0]?.statuses !== "Done"
    ) {
      await this.upsertUseCase.execute("projects", projectObjectToUpsert);
    }

    this.view.showSuccessSnackbar("Successfully Approved!");
    await this.view.navigateTo("/estimate");
  }
  async submit() {
    const estimate = await findObjectUseCase().execute("services_final");
    const queryPID = {
      where: {
        id: this.view.props.params.pid,
      },
    };
    const queryCID = {
      where: {
        id: this.view.props.params.cid,
      },
    };

    const pid = await findObjectUseCase().execute("projects", queryPID);
    const cid = await findObjectUseCase().execute("clients", queryCID);

    let highestEstNo = 0;
    estimate.forEach((item) => {
      if (item.estimate_num && item.estimate_num > highestEstNo) {
        highestEstNo = item.estimate_num;
      }
    });
    const object = this.view.getObject();

    const collection = "services_final";
    if (this.view.props.params.pid && this.view.props.params.cid) {
      object.project_name = pid[0];
      object.client_name = cid[0];
    } else if (this.change.project_name || !this.object.project_name) {
      object.project_name = this.view.state.selectedProject
        ? this.view.state.selectedProject
        : this.object.project_name;
    }
    object.downpaymentVal = this.view.state.downpaymentVal || 0;
    object.statuses = "Pending";
    object.estimate_type = "Event";
    object.estimate_num = this.view.getParams().hasOwnProperty("id")
      ? object.estimate_num
      : highestEstNo + 1;
    object.asf = this.view.state.object.asf || 0;
    object.discount = this.view.state.object.discount || 0;

    object.estimateTotal = this.view.state.object.total || 0;

    object.services = object.services.filter(
      (service) => service.__operation !== "REMOVE"
    );

    let updatedDescriptions = sessionStorage.getItem("updatedDescriptions");
    if (updatedDescriptions) {
      // Parse the JSON string into an array of objects
      updatedDescriptions = JSON.parse(updatedDescriptions);

      const query = {
        where: {
          id: object.id,
        },
      };

      const response = await findObjectUseCase().execute(collection, query);
      console.log("response: ", response);

      // Initialize the descriptionArray if it's not already there
      if (!object.descriptionArray) {
        object.descriptionArray = [];
      }

      // Iterate over updatedDescriptions and update the corresponding descriptionArray entry based on categoryIndex
      updatedDescriptions.forEach((updatedDesc) => {
        // Find the matching categoryIndex in the existing descriptionArray
        const existingDescIndex = object.descriptionArray.findIndex(
          (desc) => desc.categoryIndex === updatedDesc.categoryIndex
        );

        if (existingDescIndex !== -1) {
          // Update the existing description entry
          object.descriptionArray[existingDescIndex].description =
            updatedDesc.description;
        } else {
          // If categoryIndex doesn't exist, add the new description entry
          object.descriptionArray.push({
            description: updatedDesc.description,
            categoryIndex: updatedDesc.categoryIndex,
          });
        }
      });

      console.log("Updated DescriptionArray", object.descriptionArray);
    } else {
      console.log("No updatedDescriptions found in sessionStorage");
    }

    // Filter out deleted descriptions from descriptionArray
    object.descriptionArray = object.descriptionArray.filter(
      (desc) => !desc.__operation || desc.__operation !== "REMOVE"
    );

    object.client_name = { id: object.client_name.id };
    object.name = { id: object.client_name.id };
    object.project_name = { id: object.project_name.id };

    console.log("final send", object);

    await this.upsertUseCase.execute(collection, object);
    this.view.submissionSuccess();
    this.view.showSuccessSnackbar("Successfully saved!");
    sessionStorage.removeItem("updatedDescriptions");
    sessionStorage.setItem("estimateRefresh", true);
    // this.view.navigateBack();
    this.view.navigateTo("/estimate");

    this.view.reload();
    // this.view.reload();

    // if (updatedDescriptions) {
    //   // Parse the JSON string into an array of objects
    //   updatedDescriptions = JSON.parse(updatedDescriptions);

    //   // Map over the array to extract the descriptions and categoryIndex
    //   // object.descriptionArray = updatedDescriptions.map((desc) => {
    //   //   return {
    //   //     description: desc.description,
    //   //     categoryIndex: desc.categoryIndex,
    //   //   };
    //   // });

    //   object.descriptionArray = updatedDescriptions
    //     .filter((desc) =>
    //       object.services.some((service, index) => index === desc.categoryIndex)
    //     )
    //     .map((desc) => ({
    //       description: desc.description,
    //       categoryIndex: desc.categoryIndex,
    //     }));

    //   console.log("UD", updatedDescriptions);
    // } else {
    //   console.log("No updatedDescriptions found in sessionStorage");
    // }

    // console.log("final send", object);

    // await this.upsertUseCase.execute(collection, object);
    // this.view.submissionSuccess();
    // this.view.showSuccessSnackbar("Successfully saved!");
    // sessionStorage.removeItem("updatedDescriptions");
    // this.view.navigateBack();
  }
  async cancel() {
    dialog.fire({
      html: (
        <div className="text-end">
          <div className="text-center p-4">
            <i
              className={classNames("bi bi-emoji-frown", "text-danger")}
              style={{ fontSize: "5rem" }}
            ></i>
            <h4 className="fw-bold">Cancelled!</h4>
            <p className="m-0">Operation has been cancelled.</p>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F" }}
              onClick={() => dialog.close()}
            >
              Okay
            </button>
          </div>
        </div>
      ),
      footer: false,
    });

    await this.view.navigateTo("/estimate");
  }
}

export default EventPresenter;
